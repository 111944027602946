<script>
import Layout from "../../layouts/main";
import StudentLayout from '../../layouts/studentLayout';
import errorHandler from '@/helpers/errorHandler'
import  ConfirmActionDialog  from "@/components/ConfirmActionDialog";
import moment from 'moment';
import userService from "../../../helpers/userService";

/**
 * Starter component
 */
export default {
  name: "Studentdetails",
  page:{
    title: 'Student Info'
  },
  components:{
    Layout,
    StudentLayout,
    ConfirmActionDialog,
  }, 
  async created(){
    this.baseUrl = process.env.VUE_APP_BASE_URL;
    this.role = userService.methods.userRole();
    await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
        this.profileData = this.$store.getters['student/getStudent'];
    }).then(()=>{}).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch('student/getStudentLogins',this.$route.params.student).then(()=>{
    }).then(()=>{}).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch('statistics/getStudentStat',this.$route.params.student).then(()=>{
        this.number = this.$store.getters['statistics/studentCount'];
    }).then(()=>{}).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
    await this.$store.dispatch('statistics/getStudentStatOnline',this.$route.params.student).then(()=>{
        this.numberOnline = this.$store.getters['statistics/studentCountOnline'];
    }).then(()=>{}).catch( err =>{
      // errorHandler.methods.errorMessage(err);
      console.log("No items")
    });
  },
  computed:{
    logins(){
      return this.$store.getters['student/getStudentLogs'];
    }
  },
  data() {
    return {
      baseUrl: null,
      profileData:{},
      role:null,
      number: 0,
      numberOnline: 0,
      editName: false,
      newName:'',
      editPhone: false,
      newPhone:'',
      editParent1: false,
      newParent1:'',
      editParent2: false,
      newParent2:'',
      editBalance: false,
      newBalance: null,
    };
  },
  methods:{
    async resetLogins(){
      await this.$store.dispatch('student/resetLogins',this.$route.params.student).then(
          async ()=>{
            errorHandler.methods.successful('The logins reset successfully!');
            await this.$store.dispatch('student/getStudentLogins',this.$route.params.student).then(()=>{
            }).then(()=>{}).catch( err =>{
              // errorHandler.methods.errorMessage(err);
              console.log("No items")
            });
          }
      ).catch((err)=>{
        errorHandler.methods.error(err);
      });
    },
    RemoveRecord:function(){
     this.$store.dispatch("student/deleteStudent",this.recordId).then((token)=>{
      errorHandler.methods.successful('Item deleted successfully!')
      this.$router.push('/students/search')
     }).catch(err =>{
      errorHandler.methods.errorMessage(err);
     });
     this.$bvModal.hide('deleteRecord')
    },
     showDeleteModal(id) {
            this.$bvModal.show('deleteRecord')
             this.recordId = id
    },
    changeName(){
      this.editName = true;
      this.newName = this.profileData.user.name
    },
    cancelName(){
      this.editName = false;
    },
    async updateNewName(){
      await this.$store.dispatch('student/updateNewName',{name: this.newName, stud_id: this.$route.params.student}).then(
          async ()=>{
            errorHandler.methods.successful('The name changed successfully!');
            this.editName = false;
            await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
                this.profileData = this.$store.getters['student/getStudent'];
            }).then(()=>{}).catch( err =>{
              // errorHandler.methods.errorMessage(err);
              console.log("No items")
            });
          }
      ).catch((err)=>{
        errorHandler.methods.error(err);
      });
    },
    changePhone(){
      this.editPhone = true;
      this.newPhone = this.profileData.user.phone_number;
    },
    cancelPhone(){
      this.editPhone = false;
    },
    async updateNewPhone(){
      await this.$store.dispatch('student/updateNewPhone',{phone: this.newPhone, stud_id: this.$route.params.student}).then(
          async ()=>{
            errorHandler.methods.successful('The phone changed successfully!');
            this.editPhone = false;
            await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
                this.profileData = this.$store.getters['student/getStudent'];
            }).then(()=>{}).catch( err =>{
              // errorHandler.methods.errorMessage(err);
              console.log("No items")
            });
          }
      ).catch((err)=>{
        errorHandler.methods.error(err);
      });
    },
    changeParent1(){
      this.editParent1 = true;
      this.newParent1 = this.profileData.phone_no_1;
    },
    cancelParent1(){
      this.editParent1 = false;
    },
    async updateNewParent1(){
      await this.$store.dispatch('student/updateNewParent1',{phone: this.newParent1, stud_id: this.$route.params.student}).then(
          async ()=>{
            errorHandler.methods.successful('The phone changed successfully!');
            this.editParent1 = false;
            await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
                this.profileData = this.$store.getters['student/getStudent'];
            }).then(()=>{}).catch( err =>{
              // errorHandler.methods.errorMessage(err);
              console.log("No items")
            });
          }
      ).catch((err)=>{
        errorHandler.methods.error(err);
      });
    },
    changeParent2(){
      this.editParent2 = true;
      this.newParent2 = this.profileData.phone_no_2;
    },
    cancelParent2(){
      this.editParent2 = false;
    },
    async updateNewParent2(){
      await this.$store.dispatch('student/updateNewParent2',{phone: this.newParent2, stud_id: this.$route.params.student}).then(
          async ()=>{
            errorHandler.methods.successful('The phone changed successfully!');
            this.editParent2 = false;
            await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
                this.profileData = this.$store.getters['student/getStudent'];
            }).then(()=>{}).catch( err =>{
              // errorHandler.methods.errorMessage(err);
              console.log("No items")
            });
          }
      ).catch((err)=>{
        errorHandler.methods.error(err);
      });
    },
    changeBalance(){
      this.editBalance = true;
      this.newBalance = this.profileData.balance;
    },
    cancelBalance(){
      this.editBalance = false;
    },
    async updateNewBalance(){
      await this.$store.dispatch('student/updateNewBalance',{balance: this.newBalance, stud_id: this.$route.params.student}).then(
          async ()=>{
            errorHandler.methods.successful('The balance changed successfully!');
            this.editBalance = false;
            await this.$store.dispatch('student/getStudentInfo',this.$route.params.student).then(()=>{
                this.profileData = this.$store.getters['student/getStudent'];
            }).then(()=>{}).catch( err =>{
              // errorHandler.methods.errorMessage(err);
              console.log("No items")
            });
          }
      ).catch((err)=>{
        errorHandler.methods.error(err);
      });
    },
  },
  filters:{
    formatDate(value){
      if(value){
        return moment(String(value)).format('hh:mm A DD/MM/YYYY')
      }
    }
  }
};
</script>

<template>
  <Layout>
    <StudentLayout :student="profileData">
        <b-modal id="deleteRecord" size="md" title="Warning Action">
          <ConfirmActionDialog/>
          <template #modal-footer>
            <b-button variant="outline-success" @click="RemoveRecord()">Delete</b-button>
            <b-button variant="outline-danger" @click="$bvModal.hide('deleteRecord')">Cancel</b-button>
          </template>
        </b-modal>
        <div>
            <h5 class="mb-3 text-uppercase bg-light p-2">
                <i class="mdi mdi-account-circle mr-1 "></i> Basic Info
            </h5>
            <table class="table table-borderless table-sm">
            <tbody class="row">
                <tr class="col-md-6">
                <th scope="row">Student Name:</th>
                <td v-if="profileData.user">
                  <div v-if="!editName">
                    {{ profileData.user.name}}
                    <b-button v-if="[0, 3].includes(role)" variant="outline-warning" class="m-0 p-0" @click="changeName"><i class="mdi mdi-pencil"></i></b-button>
                  </div>
                  <div v-else>
                    <b-form inline>
                      <b-form-group>
                        <b-form-input
                          id="input-1"
                          v-model="newName"
                          type="text"
                          placeholder="Enter Name"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-button variant="light" class="p-0" @click="updateNewName">✔️</b-button>
                      <b-button variant="light" class="p-0" @click="cancelName">❌</b-button>
                    </b-form>
                  </div>
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Username:</th>
                <td v-if="profileData.user">
                    {{ profileData.user.user_name}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Phone:</th>
                <td v-if="profileData.user">
                  <div v-if="!editPhone">
                    {{ profileData.user.phone_number}}
                    <b-button v-if="[0, 3].includes(role)" variant="outline-warning" class="m-0 p-0" @click="changePhone"><i class="mdi mdi-pencil"></i></b-button>
                  </div>
                    <div v-else>
                    <b-form inline>
                      <b-form-group>
                        <b-form-input
                          id="input-1"
                          v-model="newPhone"
                          type="text"
                          placeholder="Enter Phone"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-button variant="light" class="p-0" @click="updateNewPhone">✔️</b-button>
                      <b-button variant="light" class="p-0" @click="cancelPhone">❌</b-button>
                    </b-form>
                  </div>
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Stage:</th>
                <td v-if="profileData['educational _year']">
                    {{ profileData["educational _year"] }}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">School:</th>
                <td v-if="profileData.school">
                    {{ profileData.school}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Parent phone 1:</th>
                <td v-if="profileData.phone_no_1">
                  <div v-if="!editParent1">
                    {{ profileData.phone_no_1}}
                    <b-button v-if="[0, 3].includes(role)" variant="outline-warning" class="m-0 p-0" @click="changeParent1"><i class="mdi mdi-pencil"></i></b-button>
                  </div>
                    <div v-else>
                    <b-form inline>
                      <b-form-group>
                        <b-form-input
                          id="input-1"
                          v-model="newParent1"
                          type="text"
                          placeholder="Enter Phone"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-button variant="light" class="p-0" @click="updateNewParent1">✔️</b-button>
                      <b-button variant="light" class="p-0" @click="cancelParent1">❌</b-button>
                    </b-form>
                  </div>
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Parent phone 2:</th>
                <td v-if="profileData.phone_no_2">
                  <div v-if="!editParent2">
                    {{ profileData.phone_no_2}}
                    <b-button v-if="[0, 3].includes(role)" variant="outline-warning" class="m-0 p-0" @click="changeParent2"><i class="mdi mdi-pencil"></i></b-button>
                  </div>
                    <div v-else>
                    <b-form inline>
                      <b-form-group>
                        <b-form-input
                          id="input-1"
                          v-model="newParent2"
                          type="text"
                          placeholder="Enter Phone"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-button variant="light" class="p-0" @click="updateNewParent2">✔️</b-button>
                      <b-button variant="light" class="p-0" @click="cancelParent2">❌</b-button>
                    </b-form>
                  </div>
                </td>
                <td v-else>
                  <div v-if="!editParent2">
                    {{ '' }}
                    <b-button v-if="[0, 3].includes(role)" variant="outline-warning" class="m-0 p-0" @click="changeParent2"><i class="mdi mdi-pencil"></i></b-button>
                  </div>
                    <div v-else>
                    <b-form inline>
                      <b-form-group>
                        <b-form-input
                          id="input-1"
                          v-model="newParent2"
                          type="text"
                          placeholder="Enter Phone"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-button variant="light" class="p-0" @click="updateNewParent2">✔️</b-button>
                      <b-button variant="light" class="p-0" @click="cancelParent2">❌</b-button>
                    </b-form>
                  </div>
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Student ID:</th>
                <td v-if="profileData.code">
                    {{ profileData.code}}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Balance:</th>
                <td v-if="profileData.balance >=0">
                  <div v-if="!editBalance">
                    {{ profileData.balance}}
                    <b-button v-if="[0, 3].includes(role)" variant="outline-warning" class="m-0 p-0" @click="changeBalance"><i class="mdi mdi-pencil"></i></b-button>
                  </div>
                    <div v-else>
                    <b-form inline>
                      <b-form-group>
                        <b-form-input
                          id="input-1"
                          v-model="newBalance"
                          type="text"
                          placeholder="Enter current Balance"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-button variant="light" class="p-0" @click="updateNewBalance">✔️</b-button>
                      <b-button variant="light" class="p-0" @click="cancelBalance">❌</b-button>
                    </b-form>
                  </div>
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Attended Physically:</th>
                <td v-if="number >= 0">
                    {{ number }}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Attended Online:</th>
                <td v-if="numberOnline >= 0">
                    {{ numberOnline }}
                </td>
                </tr>
                <tr class="col-md-6">
                <th scope="row">Total Attended:</th>
                <td v-if="number >= 0 && numberOnline >= 0">
                    {{ number + numberOnline }}
                </td>
                </tr>
            </tbody>
            </table>
            <div>
              <h3>Logins:
                <b-button v-if="[0, 3].includes(role)" class="ml-2" variant="outline-danger" pill @click="resetLogins"> Reset Logins</b-button>
              </h3>
              <ul>
                <li v-for="item in logins" :key="item.id">
                  - {{ item.login_stamp }} ~ {{ item.timestamp | formatDate }}
                </li>
              </ul>
            </div>
            <div class="text-center justify-content-center mb-3" v-if="profileData && profileData.qr_code">
              <div class="auth-logo">
                  <span class="logo-lg">
                  <img
                      :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'qr_codes'}/${this.profileData.qr_code}.png`"
                      alt=""
                      height="220"
                  />
                  </span>
              </div>
              <a class="btn btn-outline-success btn-rounded" :download="this.profileData.qr_code+'.png'" :href="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'qr_codes'}/${this.profileData.qr_code}.png`">
                  <span class="mdi mdi-download">
                      Download Qrcode
                  </span>
              </a>
          </div>
            <b-container class="bv-example-row bv-example-row-flex-cols">
            <b-row>
                <b-col class="col-md-4 offset-md-8 offset-0">
                <router-link :to="'/students/'+this.$route.params.student+'/updateCredentials'">
                    <b-button variant="outline-warning" pill>
                    <i class="fas fa-pen mr-1"></i>
                    Update username and password 
                    </b-button>
                </router-link>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col class="col-md-4 offset-md-8 offset-0">
                  <b-button variant="outline-danger" pill style="width: 100%;"
                    @click="showDeleteModal($route.params.student)"
                  ><i
                    class="mdi mdi-delete mr-2 text-muted font-18 vertical-middle iconGray"
                  ></i
                  >Delete
                  </b-button>
                </b-col>
            </b-row>
            </b-container>
            
        </div>
    </StudentLayout>
  </Layout>
</template>

<style scoped>
ul {
  list-style-type: none;
}

.tab-content {
  border: none;
}

.list-group-item {
  border: none;
}

i {
  font-size: 20px;
}
</style>
